import './PageHero.scss';

// import LetysLogo2 from '../../assets/images/letys-logo2.png';

export default function PageHero() {
    return (
        <>
            <div className='hero'>
                <div className='hero__title'>
                    {/* <img src={Logo2} alt='Letys Buko Pie logo but vertical' /> */}
                </div>
            </div>
        </>
    )
}